<template>
  <div class="container">
    <h3>Ваши задачи</h3>
    <p>Здесь находятся задачи по всем вашим проектам, где вы являетесь исполнителем, создателем или наблюдателем.
      <br><b>Для создания задачи перейдите в соответствующий проект</b></p>
    <v-progress-circular v-if="loading"
                         :size="50"
                         color="primary"
                         indeterminate
    ></v-progress-circular>

    <Board :preSelectedTaskID="id" v-else :tasks="tasks"/>
  </div>
</template>

<script>
import Board from "@/components/Tasks/Board.vue";
import tasks from "@/api/tasks.js";
import analytics from "@/api/analytics.js";

export default {
  name: "MainBoardTasksComponent",
  components: {Board},
  props: ['id'],
  data() {
    return {
      tasks: [],
      loading: true
    }
  },
  mounted() {
    if (!this.$store.state.user.currentUser.id) return this.$router.push('/auth');
    tasks.user_tasks({id: this.$store.state.user.currentUser.id}).then((r) => {
      this.tasks = r.data;
      this.loading = false;
      analytics.openAllTasksPage(this.$store.state.user.currentUser.id);
    })
  }
}
</script>

<style scoped>

</style>
